<template>
  <div>
    <v-row class="ml-1">
      <v-col cols="8" sm="8" md="8" class="text-left">
        <v-btn class="text-capitalize primary" rounded @click="goBack">
          <v-icon class="white primary--text rounded-xl pa-1 mr-1 ml-n3" small>
            mdi-arrow-left
          </v-icon>
          Go Back
        </v-btn>
      </v-col>
      <v-col cols="4" sm="4" md="4" class="text-right">
        <v-toolbar flat class="transparent ml-n4 mb-4 text-right">
          <p>Folder / {{ fetch_children_folders.name }}</p>
        </v-toolbar>
      </v-col>
    </v-row>
    <div>
      <folders
        @load-folders="fetchFolders"
        :folders-details="displayedFolders"
      ></folders>
      <v-btn
        v-if="remainingFolders.length > 0"
        @click="showMore"
        class="capitalize show-more-btn"
        :elevation="0"
        rounded
        dense
      >
        Show More ({{ remainingFolders.length }})
      </v-btn>
    </div>
    <div class="mt-4">
      <v-toolbar flat class="transparent font-weight-bold ml-n4">
        Recordings
      </v-toolbar>
      <recordings :recordings="fetch_children_folders.recordings"></recordings>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ResponseHelper from "../../utils/ResponseHelper";
import Folders from "../../components/MyFolders/Folders.vue";
import Recordings from "../../components/MyFolders/Recordings.vue";
export default {
  name: "FoldersView",
  components: {
    Folders,
    Recordings,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      toast: new ResponseHelper(),
      favicon_url: "",
      fetch_children_folders: {
        children: [],
      },
      my_recording: {},
      displayLimit: 12,
    };
  },
  mounted() {
    this.fetchFolders(this.id);
  },
  watch: {
    id: {
      handler(currentId) {
        this.fetchFolders(currentId);
      },
    },
  },
  created() {},
  computed: {
    displayedFolders() {
      return this.fetch_children_folders.children.slice(0, this.displayLimit);
    },
    remainingFolders() {
      return this.fetch_children_folders.children.slice(this.displayLimit);
    },
  },
  methods: {
    ...mapActions("folders", ["show_folder_details"]),

    showMore() {
      this.displayLimit += 8;
    },
    async fetchFolders(Id) {
      try {
        const response = await this.show_folder_details(Id);
        this.fetch_children_folders = response.data;
        this.$store.dispatch("recordings/dashboard_my_recordings");
        this.$store.dispatch("folders/my_folders");
      } catch (e) {
        this.toast.sendError(e);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.show-more-btn {
  border: 1px solid #ddd !important;
  background-color: #fff !important;
}
.show-more-btn:hover {
  border: 1px solid blue !important;
  background-color: #fff !important;
  font-weight: 400;
}
</style>
